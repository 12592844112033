.testimonials{
    display: flex;
    
    padding: 2rem;
    gap: 2rem;
    
}
.left-t{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
}
.left-t>:nth-child(2){
   font-size: 3rem;
   text-transform: uppercase;
   font-weight: bold;
}
.left-t>:nth-child(3){
   font-size: 3rem;
   text-transform: uppercase;
   font-weight: bold;
}
.left-t>:nth-child(4){
   text-transform: none;
   line-height: 30px;
   letter-spacing: 1px;

}
.left-t>div{
    display: flex;
    text-transform: uppercase;
    font-weight: 1.5rem;
    text-align: justify;
}
.left-t>div>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
    
}
.right-t{
    flex: 1;
    display: flex;
    position: relative;
    gap: 2rem;
}
.right-t>img{
    width: 12rem;
    height: 14rem;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 8rem;
    top: 3rem;
    z-index: 3;
    transition: 2s linear;
}
.box1{
    display: flex;
    position: absolute;
    width: 12rem;
    height: 14rem;
    background: var(--planCard);
    right: 7.2rem;
    top: 3.7rem;
    z-index: 2;
}
.box2{
    display: flex;
    position: absolute;
    width: 12rem;
    height: 14rem;
    border: 2px solid var(--orange);
    right: 8.5rem;
    top: 2rem;
    z-index: 1;
}
.arrow{
    display: flex;
    position: absolute;
    gap: 1rem;
    right: 22rem;
    top: 15rem;
    transform: scale(0.6);
    cursor: pointer;
    
}
.arrow>:nth-child(2){
    width: 3rem;
}
.arrow>:nth-child(1){
    width: 3rem;
}
.t-blur{
    width: 15rem;
    height: 18rem;
    left: 0;
    
}
@media screen and (max-width: 768px){
    .testimonials{
        flex-direction: column;
        
    }
    .left-t{
        gap: 1rem;
    }
    .left-t>:nth-child(2){
        font-size: x-large;
    }
    .left-t>:nth-child(3){
        font-size: x-large;
    }
   

    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

    }
    .right-t>div{
        position: relative;
        display: none;
    }
    .right-t>img{
        top: 0;
        right: 0;
        position: relative;
        align-self: center;
    }
    .right-t>:last-child{
        display: block;
      
        left:0;
        top: 0;
    }
}