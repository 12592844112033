.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top:1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h{
    flex:1;
    position: relative;
    background-color: var(--orange);

}

.the-best-ad{
    margin-top: 4rem;
    background-color: var(--orange);
    border-radius: 4rem;
    width: fit-content;
    padding: 0.5rem 1rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div:nth-child(1){
    position: absolute;
    background-color: var(--appColor);
    width: 5rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
    filter: blur(10px)
}
.the-best-ad>div:nth-child(2){
    position: absolute;
    background-color: var(--appColor);
    width: 5rem;
    height: 80%;
    right: 8px;
    border-radius: 3rem;
    z-index: 1;
    filter: blur(10px)
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    color: white;
    text-overflow: inherit;
    font-weight: bold;
}
.hero-text>div:nth-of-type(3){
   font-size: 1rem;
   text-transform: none;
   font-weight: 250;
   width: 80%;
   letter-spacing: 1px;
    
}
.figures{
    display: flex;
    gap:2rem;
}
.figures>div>span:nth-of-type(1){
    display: flex;
    flex-direction: column;
    gap:2rem;
    
    color: white;
    align-items: center;
    position: relative;
    z-index: 1;

}
.figures>div>span:nth-of-type(2){
    color:white;

}

.circle {
    position: relative;
    padding: 20px;
    margin: 5px;
    display: inline-block;
    font-size: 30px;
  }
  
  .circle::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100px;
    padding: 10px; /* control the border thickness */
    background: linear-gradient(45deg,blue,var(--orange));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }

  .hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
  }
  .hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
    
  }
  .hero-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
  }

  .right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
  }
  .heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
  }
  .heart-rate>img{
    width: 2rem;
  }
 .heart-rate>:nth-child(2){
    color: var(--gray);
 }
 .heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
 }
 .hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
    z-index: 2;
 }
 .hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: 1;
    width: 15rem;
 }

 .calories{
  display: flex;
  /* align-items: center;
  justify-content: center; */
  
  position: absolute;
  gap: 2rem;
  background-color: var(--appColor);
  top: 30rem;
  right: 30rem;
  padding: 0.5rem;
  width: 12rem;
  border-radius: 5px;
  
 }

 .calories>img{
width: 3rem;
 } 
 
 .calories>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }
 .calories>div>:nth-of-type(1){
  color: var(--gray);
  font-size: 14px;
 }
 .calories>div>span:nth-of-type(2){
  color: white;
 font-size: 20px;
 }
.hero-blur{
 width: 17rem;
 height: 24rem;
left: 0;
}

@media screen and (max-width: 768px){
  .hero{
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .the-best-ad{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.9);
  }
  .hero-text{
    display: flex;
    font-size: 2rem;
    gap: 0.5rem;
    align-self: center;
    justify-content: center;
    align-items: center;
    
  }
  .hero-text>div:nth-child(3){
   font-size: small;
   font-weight: 200;
   letter-spacing: 1px;
   text-align: center;
   
  }
  .figures{
    gap: 1.1rem;
    left: 1rem;
    display: flex;
    justify-content: space-around;

  }
  .figures>div>span{
    font-size: 18px;
    
  }
  .circle{
    padding: 15px;
  }
  .figures>div>span:nth-of-type(1){
    font-size:large;
    
   
  }
  .figures>div>span:nth-of-type(2){
    font-size: small;
    position: relative;
  left: 1rem;    

   
  }
  .hero-buttons{
    display: flex;
    justify-content: center;
  }
  .right-h{
    position: relative;
    background: none;
  }
  .heart-rate{
    left: 1rem;
    top: 2rem;
  
  }
  .calories{
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories>div>:nth-child(2){
    columns: white;
    font-size: 1rem;
  }
  .hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
  }
  .hero-image-back{
    width: 15rem;
    left: 2rem;
    top: 0rem;
    z-index: -9;
  }

}