.join{
display: flex;
padding: 0 1rem;
gap: 10rem;

}

.left-j{
    position: relative;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;

    
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
   top: -1.5rem;
    border: 2px solid var(--orange);
   border-radius: 20%;
  
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: center;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: var(--gray);
    padding: 1rem 2rem;
    border-radius: 10px;
    
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--darkGray);
}
::placeholder{
    color: white;
}
.btn-j{
    background-color: var(--orange);
    color: white;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: xx-large;
    }
   .right-j{
    padding: 2rem;
   }
}