.plans-container{
    display: flex;
    padding: 0.2rem;
    gap: 2rem;
    flex-direction: column;
}
.plans-header{
    display: flex;
    font-size: 3rem;
    justify-content: center;
    color: white;
    font-weight: bold;
    gap: 2rem;
    
    font-style: italic;
}

.plans{
    display: flex;
    position: relative;
   justify-content: center;
    gap: 1rem;
    color: white;
    font-weight: bold;

   

}
.plan{
    display: flex;
    flex-direction: column;
   
    background: var(--caloryCard);
    justify-content: center;
    width: fit-content;
    gap: 1.5rem;
    padding: 2rem;
    color: white;
    transition:  0.5s ease-out;
} 
.plan>:nth-child(1){
    fill: white;
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
   color: var(--darkGrey);
   font-size: 1.5rem;
}
.plan>:nth-child(3){
    font-size: 3rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    color: white;
    font-size: 1rem;
}
.feature>img{
    width: 1.3rem;
    height: 1.3rem;
}
.benefits{
    display: flex;
    gap: 0.8rem;
    align-items: center;
    
}
.benefits>img{
    display: flex;
    align-items: center;
}
.btn{
    color: var(--darkGrey);
}

.plan:hover{
    background: var(--planCard);
    
    transform: scale(1.1);
}
.plan-blur1{
    width: 15rem;
    height: 17rem;
    left: 0;
}
.plan-blur2{
    width: 15rem;
    height: 17rem;
    right: 0;
}

@media screen and (max-width: 768px){
    .plans-header{
        flex-direction: column;
        font-size: x-large;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .plans{
        flex-direction: column;
        padding: 2rem;
        
    }
    .plan{
        gap: 1rem;
        padding: 1rem;
    }
    .plan>:nth-child(3){
        font-size: xx-large;
    }
    .feature{
        gap: 0.8rem;
        font-size: small
    }
}
